<template>
    <section id="team" class="team">

        <div class="container section-title" data-aos="fade-up">
            <h2>{{ $t('team_title') }}</h2>
            <p>{{ $t('team_sub') }}</p>
        </div>

        <div class="container">

            <div class="row gy-5">

                <div class="col-lg-4 col-md-4 member" data-aos="fade-up" data-aos-delay="100" 
                v-for="(item, index) in teams" :key="index">
                    <div class="member-img">
                        <img :src="item.image" class="img-fluid" :alt="item.name">
                        <div class="social">
                            <NuxtLink :to="`mailto:${item.mail}`"><i class="bi bi-envelope"></i></NuxtLink>
                            <NuxtLink :to="item.telegramUrl" target="_blank"><i class="bi bi-telegram"></i></NuxtLink>
                        </div>
                    </div>
                    <div class="member-info text-center">
                        <h3>{{ item.name }}</h3>
                        <span>{{ item.position }}</span>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>
<script setup lang="ts">

const teams = ref([
    {
        image: '/images/teams/NguyenVanHien.jpg',
        mail: 'hiennv@nextcore.vn',
        telegramUrl: 'https://t.me/+84378962625',
        name: 'Nguyễn Văn Hiền',
        position: 'Chief Executive Officer'
    },
    {
        image: '/images/teams/TranDucAnh.jpg',
        mail: 'anhtd@nextcore.vn',
        telegramUrl: 'https://t.me/Anhtran201',
        name: 'Trần Đức Anh',
        position: 'Chief Executive Officer'
    },
    {
        image: '/images/teams/PhanThanhTu.png',
        mail: 'tupt@nextcore.vn',
        telegramUrl: 'https://t.me/MrTus97',
        name: 'Phan Thanh Tú',
        position: 'Project Manager'
    }
])
</script>