<template>
    <section id="about" class="about mt-md-5">
        <div class="container">

            <div class="row">
                <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="150">
                    <img src="/images/aboutus.jpg" class="img-fluid round-lg" alt="aboutus">
                </div>
                <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                    <h3 v-if="dataInfo">{{ dataInfo?.company_name ?? ''  }}</h3>
                    <p class="fst-italic" v-if="dataInfo">
                        {{ t('about_time') + convertDate(dataInfo.date_created, locale) }}
                    </p>
                    <ul>
                        <li><i class="bi bi-check-circle"></i>{{ $t('about_specializing') }}</li>
                        <li><i class="bi bi-check-circle"></i>{{ $t('about_company') }}
                        </li>
                        <li><i class="bi bi-check-circle"></i>{{ $t('about_continuously') }}</li>
                    </ul>
                </div>
            </div>

        </div>
        <ClientStats />
    </section>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useInfoStore } from '~/stores/info/info-store'

const infoStore = useInfoStore()
const { dataInfo} = storeToRefs(infoStore)
const { t, locale } = useI18n()

</script>
