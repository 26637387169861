<template>
    <section id="home" class="home">

        <div class="container-fluid" data-aos="fade-up">
            <div class="row justify-content-center">
                <div
                    class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h1 v-if="dataInfo">{{ dataInfo?.company_name ?? ''  }}</h1>
                    <h2>BUILD TRUST, CREATE VALUE</h2>
                    <div><NuxtLinkLocale to="/" @click="scrollOption('#about')" class="read-more mt-5"><span>{{ $t('about_btn') }}</span><i
                                class="bi bi-arrow-right"></i></NuxtLinkLocale></div>
                </div>
                <div class="col-xl-4 col-lg-6 order-1 order-lg-2 home-img" data-aos="zoom-in" data-aos-delay="150">
                    <img src="/images/hero-img.png" class="img-fluid animated" alt="hero-img">
                </div>
            </div>
        </div>

    </section>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useInfoStore } from '~/stores/info/info-store'
const infoStore = useInfoStore()
const {dataInfo} = storeToRefs(infoStore)

const scrollOption = (classTag: any) => {
    setTimeout(() => { 
        const scroll = document.querySelector(classTag) as HTMLElement
        if (scroll) {
            scroll?.scrollIntoView({ behavior: 'smooth' });
        }
     }, 100)
}
</script>
