<template>
    <section id="technology" class="technology">

        <div class="container section-title" data-aos="fade-up">
            <h2>{{ $t('technology_title') }}</h2>
        </div>

        <div class="container">
            <div class="row custom-technology">
                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 pr-0 pl-0" data-aos="fade-up"
                    data-aos-delay="150" v-for="(item, index) in technology" :key="index">
                    <div class="icon-box">
                        <div class="icon"><i :class="item.icon"></i></div>
                        <h3><NuxtLinkLocale to="/" @click="scrollOption('#technology')">{{ item.title }}</NuxtLinkLocale></h3>
                        <p>{{ item.sub }}</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">

            </div>
        </div>

    </section>
</template>
<script setup lang="ts">

const technology = ref([
    {
        icon: 'bi bi-stack',
        title: 'Platform',
        sub: 'Drupal, Salesforce, Sitecore ...'
    },
    {
        icon: 'bi bi-code-slash',
        title: 'Language',
        sub: 'Python, C#, Java, PHP, JS, Apex, ...'
    },
    {
        icon: 'bi bi-database',
        title: 'Database',
        sub: 'MySQL, PostgreSQL, SQLite, DB2, ...'
    },
    {
        icon: 'bi bi-diagram-3-fill',
        title: 'Framework',
        sub: 'Flask, .NET, Laravel, React, Vue, ...'
    },
    {
        icon: 'bi bi-cloud',
        title: 'Cloud',
        sub: 'AWS, Azure, Google Cloud, ...'
    },
    {
        icon: 'bi bi-ubuntu',
        title: 'OS',
        sub: 'Windows, Ubuntu, Centos, ...'
    }
])

const scrollOption = (classTag: any) => {
    setTimeout(() => { 
        const scroll = document.querySelector(classTag) as HTMLElement
        if (scroll) {
            scroll?.scrollIntoView({ behavior: 'smooth' });
        }
     }, 100)
}
</script>
