<template>
    <main id="main">

        <ClientSlider />

        <ClientAbout />

        <ClientTechnology />

        <ClientMessages />

        <ClientTeam />

        <!-- <ClientBulding /> -->

        <ClientContact />

    </main>
</template>
