<template>
    <section id="contact" class="contact section-bg">
        <div class="container" data-aos="fade-up">

            <div class="section-title">
                <h2>{{ $t('contact_title') }}</h2>
                <p>{{ $t('contact_sub') }}</p>
            </div>

            <div class="row" v-if="dataInfo">
                <div class="col-lg-6">
                    <div class="info-box mb-4">
                        <i class="bi bi-building-add"></i>
                        <h3>{{ $t('contact_address') }}</h3>
                        <NuxtLink :to="dataInfo.link_address" target="_blank">{{ dataInfo?.address ?? '' }}</NuxtLink>

                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="info-box  mb-4">
                        <i class="bi bi-envelope"></i>
                        <h3>Email </h3>
                        <NuxtLink :to="`mailto:${dataInfo?.email ?? ''}`">{{ dataInfo?.email ?? '' }}</NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="info-box  mb-4">
                        <i class="bi bi-telephone"></i>
                        <h3>{{ $t('contact_phone') }}</h3>
                        <NuxtLink :to="`tel:${dataInfo?.phone ?? ''}`">{{ dataInfo?.phone ?? '' }}</NuxtLink>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-lg-12 " v-if="dataInfo">
                    <iframe class="mb-4 mb-lg-0"
                        title="Map Contact Home"
                        :src="dataInfo.link_map"
                        frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useInfoStore } from '~/stores/info/info-store'
const infoStore = useInfoStore()
const {dataInfo} = storeToRefs(infoStore)

</script>
