<template>
    <div class="stats stats-custom">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">

            <div class="row gy-4">

                <div class="col-lg-4 col-md-4" v-if="dataInfo">
                    <div class="stats-item text-center w-100 h-100">
                        <span data-purecounter-start="0" :data-purecounter-end="dataInfo.customers_number ?? 0" data-purecounter-duration="1"
                            class="purecounter">{{ dataInfo.customers_number ?? 0 }}</span>
                        <p>{{ $t('stat_client') }}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4" v-if="dataInfo">
                    <div class="stats-item text-center w-100 h-100">
                        <span data-purecounter-start="0" :data-purecounter-end="dataInfo.projects_number ?? 0" data-purecounter-duration="1"
                            class="purecounter">{{ dataInfo.projects_number ?? 0 }}</span>
                        <p>{{ $t('stat_project') }}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4" v-if="dataInfo">
                    <div class="stats-item text-center w-100 h-100">
                        <span data-purecounter-start="0" :data-purecounter-end="dataInfo.employees_number ?? 0" data-purecounter-duration="1"
                            class="purecounter">{{ dataInfo.employees_number ?? 0 }}</span>
                        <p>{{ $t('stat_employee') }}</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useInfoStore } from '~/stores/info/info-store'
const infoStore = useInfoStore()
const {dataInfo} = storeToRefs(infoStore)
</script>
