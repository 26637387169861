<template>
    <section id="messages" class="features">
        <div class="container section-title" data-aos="fade-up">
            <h2>{{ $t('message_title') }}</h2>
            <h3>{{ $t('message_sub') }}</h3>
        </div>
        <div class="container">

            <div class="row gy-4 align-items-center features-item">
                <div class="col-lg-5 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h3>{{ $t('message_vision_title') }}</h3>
                    <ul>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_vision_sub1') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_vision_sub2') }}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out"
                    data-aos-delay="100">
                    <div class="image-stack">
                        <img src="/images/vision1.jpg" alt="vision 1" class="stack-front">
                        <img src="/images/vision.jpg" alt="vision" class="stack-back">
                    </div>
                </div>
            </div>
            <div class="row gy-4 align-items-stretch justify-content-between features-item ">
                <div class="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out">
                    <img src="/images/mission.png" class="img-fluid" alt="mission">
                </div>
                <div class="col-lg-5 d-flex justify-content-center flex-column" data-aos="fade-up">
                    <h3>{{ $t('message_mission_title') }}</h3>
                    <p></p>
                    <ul>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_mission_sub1') }}</span></li>
                        <li><i class="bi bi-check"></i><span>{{ $t('message_mission_sub2') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_mission_sub3') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_mission_sub4') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_mission_sub5') }}</span></li>
                    </ul>
                </div>
            </div>
            <div class="row gy-4 align-items-center features-item">
                <div class="col-lg-5 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h3>{{ $t('message_core_title') }}</h3>
                    <ul>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_core_sub1') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_core_sub2') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_core_sub3') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_core_sub4') }}</span></li>
                        <li><i class="bi bi-check"></i> <span>{{ $t('message_core_sub5') }}</span></li>
                    </ul>
                </div>
                <div class="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out"
                    data-aos-delay="100">
                    <div class="image-stack">
                        <img src="/images/corevalue1.jpg" alt="corevalue 1" class="stack-front">
                        <img src="/images/corevalue.jpg" alt="corevalue" class="stack-back">
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>
